.chat-message-reactions-styled-wrapper {
  display: flex;

  .str-chat__reaction-list {
    display: flex;
    position: relative;
    background: none;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 40px;

    &:hover {
      cursor: pointer;
    }

    .styled-reactions-list {
      display: flex;
      margin: 0px;
      align-items: center;

      .reaction-item {
        border: 1.5px solid theme(colors.gray.300);
        border-radius: 8px;
        padding: 2px 8px;
        margin-right: 8px;

        &.selected {
          border: 1.5px solid theme(colors.blue.400);
          background-color: theme(colors.blue.400);
          color: theme(colors.white);
          box-shadow: theme(boxShadow.common);
        }

        .str-chat__reaction-list--counter {
          color: theme(colors.gray.400);
        }

        .reaction-button {
          display: flex;
          align-items: center;
          gap: 6px;

          &.angry > span > span {
            background-image: url('https://getstream.imgix.net/images/emoji-sprite.png') !important;
          }
        }
      }
    }
  }
}

.chat-styled-wrapper {
  overflow: hidden;
  position: relative;
  display: flex;
  flex: 1 1 100%;

  .str-chat {
    color: theme(colors.gray.800);
    height: 100%;

    &.messaging,
    &.commerce {
      background-color: #fff;
      width: 100%;
    }

    .str-chat__edit-message-form {
      min-width: 500px;

      @media screen and (max-width: 600px) {
        min-width: 300px;
        max-width: 500px;
      }

      .str-chat__message-team-form-footer > div {
        display: flex;
        gap: 8px;

        & > button {
          font-size: 0.875rem;
          min-width: 64px;
          box-sizing: border-box;
          transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          font-family: theme(fontFamily.sans);
          font-weight: 500;
          line-height: 2.5;
          border-radius: 10px;
          text-transform: none;
          color: #fff;
          background-color: #0c5fe3;
        }

        & > button.str-chat__input-emojiselect {
          background-color: #fff;
        }
      }
    }
  }
}

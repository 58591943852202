.messaging {
  .chat-message-list-styled-wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    flex: 1;
  }

  .str-chat__list {
    .str-chat__reverse-infinite-scroll {
      padding-top: 12px !important;
    }

    padding: 0px !important;

    .str-chat__typing-indicator {
      display: none;
    }

    &.str-chat__list-limited-view {
      .str-chat__ul {
        .str-chat__li {
          &.str-chat__li--top,
          &.str-chat__li--single,
          &.str-chat__li--bottom {
            padding-top: 1px;
            padding-bottom: 1px;
            margin: 0px;
          }

          .customMessageWrapper {
            .str-chat__reaction-list {
              margin-left: 32px;
            }
          }

          &:last-of-type {
            margin: 0px 0 4px;
          }
        }
      }
    }

    .str-chat__ul {
      .str-chat__li {
        &.str-chat__li--top,
        &.str-chat__li--single,
        &.str-chat__li--bottom {
          padding-top: 1px;
          padding-bottom: 1px;
          margin: 0px;
        }

        &:last-of-type {
          margin: 4px 0 8px;
        }

        .customMessageWrapper {
          &:hover {
            background-color: theme(colors.gray.50);
            border-radius: 4px;

            .str-chat__message-simple__actions {
              border: 1px solid theme(colors.gray.300);
              background-color: theme(colors.gray.100);

              > * {
                display: flex;
              }
            }
          }
        }

        &:nth-child(-n + 4) {
          .customMessageWrapper {
            .str-chat__message-simple__actions {
              .str-chat__message-actions-box {
                top: 15px;
                bottom: unset;

                border-radius: var(--border-radius)
                  calc(var(--border-radius-sm) / 2) var(--border-radius)
                  var(--border-radius);
              }
            }
          }
        }
      }
    }

    .str-chat__date-separator {
      padding: var(--xs-p);

      .str-chat__date-separator-date {
        color: theme(colors.gray.400);
        font-size: 12px;
        font-weight: 500;
      }

      .str-chat__date-separator-line {
        background-color: theme(colors.gray.200);
      }
    }
  }
}

.str-chat__quoted-message-bubble {
  max-width: 90%;

  .str-chat__message-attachment {
    max-width: 100%;

    .str-chat__message-attachment-card--giphy {
      .str-chat__message-attachment--img {
        max-height: 225px;
        width: inherit;
      }
    }
  }
}

.chat-message-styled-wrapper {
  & * {
    word-break: break-word;
  }

  .str-chat__message-simple-status {
    left: unset;
    right: 16px;
    bottom: 16px;

    @media screen and (max-width: 960px) {
      left: unset;
      right: 12px;
      bottom: -22px;
    }

    .str-chat__message-simple-status-number {
      word-break: normal;
    }
  }

  .str-chat__message {
    gap: 4px;
    align-items: flex-start;
    display: flex;
    margin: 0;
    padding: 0px;

    &.str-chat__message--with-reactions {
      .str-chat__message-reactions-list-item {
        .online-indicator {
          display: none;
        }
      }
    }

    .str-chat__message-simple-status {
      @media screen and (max-width: 960px) {
        left: unset;
        right: 8px;
        bottom: -22px;
      }
    }

    .str-chat__reaction-selector {
      border-radius: var(--border-radius-md);
      left: unset;
      right: 0;
      top: 12px;
      z-index: 9999;
      background: #fff;
      box-shadow: theme(boxShadow.common);
    }

    .str-chat__message-inner {
      padding: 0px;
      margin-bottom: 4px;
      justify-content: unset;
      margin: 0;
      flex: 1;

      .str-chat__message-simple__actions {
        /* to me and others match */
        flex-direction: row-reverse;
        top: -16px;
        left: unset;
        right: 8px;
        border-radius: var(--border-radius-md);
        transform: scale(1.1);
        padding: 4px;
        max-width: 75px;
        z-index: 1000;

        .str-chat__message-actions-box {
          left: 0px;
          bottom: unset;
          bottom: 15px;
          transform: scale(0.9);
          border: 1px solid theme(colors.gray.200);
          border-radius: var(--border-radius) var(--border-radius)
            calc(var(--border-radius-sm) / 2) var(--border-radius);

          &.str-chat__message-actions-box--reverse {
            left: unset;
            right: -12px;
            border-radius: var(--border-radius) var(--border-radius)
              calc(var(--border-radius-sm) / 2) var(--border-radius);
          }

          &.str-chat__message-actions-box--mine {
            left: unset;
            right: -12px;
            border-radius: var(--border-radius) var(--border-radius)
              calc(var(--border-radius-sm) / 2) var(--border-radius);
          }

          &.str-chat__message-actions-box--mine.str-chat__message-actions-box--reverse {
            left: unset;
            right: -12px;
            border-radius: var(--border-radius) var(--border-radius)
              calc(var(--border-radius-sm) / 2) var(--border-radius);
          }
        }
      }

      .str-chat__message-attachment {
        max-width: calc(100% - 40px - 10px);
        margin: 0;
        display: flex;
        justify-content: left;

        .str-chat__message-attachment--img {
          max-height: 225px;
          width: auto;
          border-radius: calc(var(--border-radius-sm) / 2) var(--border-radius)
            var(--border-radius) var(--border-radius);
        }

        .str-chat__gallery-image {
          width: initial;
          height: initial;
        }

        .str-chat__message-attachment-card--giphy {
          .str-chat__message-attachment-card--header {
            img {
              max-height: 225px;
            }
          }

          .str-chat__message-attachment-card--content {
            display: none;
          }
        }
      }

      .str-chat__message-text {
        max-width: 90%;
        justify-content: flex-start;

        .str-chat__message-text-inner {
          font-size: 13px;
          max-width: 100%;
          padding: 0;
          background: none;
          border: none;
          min-height: unset;
          font-weight: 300;
          text-align: left;

          &.str-chat__message-simple-text-inner {
            color: theme(colors.gray.800);
          }

          &.str-chat__message-simple-text-inner--is-emoji {
            padding-left: 4px;
            padding-right: 4px;

            p {
              line-height: 30px;

              .inline-text-emoji {
                font-size: 30px;
              }
            }
          }

          & .inline-text-emoji {
            font-size: 21px;
          }

          .quoted-message {
            color: theme(colors.gray.800);

            .chatAvatar {
              .online-indicator {
                display: none;
              }
            }
            .quoted-message-inner {
              background: #eeeeee;
              padding: 8px;
            }
          }
        }
      }
    }

    &.str-chat__message--me {
      justify-content: flex-start;

      .str-chat__message-inner {
        .str-chat__message-simple__actions {
          top: -16px;
          left: unset;
          right: 8px;
          border-radius: var(--border-radius-md);
          transform: scale(1.1);
          padding: 4px;
          max-width: 75px;
          z-index: 1000;

          .str-chat__message-actions-box {
            left: 0px;
            bottom: unset;
            bottom: 15px;
            transform: scale(0.9);
            border: 1px solid theme(colors.gray.200);
            border-radius: var(--border-radius) var(--border-radius)
              calc(var(--border-radius-sm) / 2) var(--border-radius);

            &.str-chat__message-actions-box--reverse {
              left: unset;
              right: -12px;
              border-radius: var(--border-radius) var(--border-radius)
                calc(var(--border-radius-sm) / 2) var(--border-radius);
            }

            &.str-chat__message-actions-box--mine {
              left: unset;
              right: -12px;
              border-radius: var(--border-radius) var(--border-radius)
                calc(var(--border-radius-sm) / 2) var(--border-radius);
            }

            &.str-chat__message-actions-box--mine.str-chat__message-actions-box--reverse {
              left: unset;
              right: -12px;
              border-radius: var(--border-radius) var(--border-radius)
                calc(var(--border-radius-sm) / 2) var(--border-radius);
            }
          }
        }

        .str-chat__message-text {
          max-width: 90%;
          justify-content: flex-start;

          .str-chat__message-text-inner {
            font-size: 13px;
            max-width: 100%;
            padding: 0;
            background: none;
            border: none;
            min-height: unset;
            font-weight: 300;
            text-align: left;

            &.str-chat__message-simple-text-inner {
              color: theme(colors.gray.800);
            }

            &.str-chat__message-simple-text-inner--is-emoji {
              padding-left: 4px;
              padding-right: 4px;

              p {
                line-height: 30px;

                .inline-text-emoji {
                  font-size: 30px;
                }
              }
            }

            & .inline-text-emoji {
              font-size: 21px;
            }

            .quoted-message {
              color: theme(colors.gray.800);

              .chatAvatar {
                .online-indicator {
                  display: none;
                }
              }
              .quoted-message-inner {
                background: #eeeeee;
                padding: 8px;
              }
            }
          }
        }
      }
    }

    &.str-chat__message-quoted {
      .str-chat__message-inner {
        .str-chat__message-text {
          .str-chat__message-text-inner {
            padding: 8px;
            border: 1px solid theme(colors.gray.200);
            border-radius: calc(var(--border-radius-sm) / 2)
              var(--border-radius) var(--border-radius) var(--border-radius);
          }
        }
      }
    }

    &.str-chat__message-simple--me {
      justify-content: flex-start;
    }
  }
}

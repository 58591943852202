/* Import in order */
@import './chatMain.css';
@import './chatInput.css';
@import './chatChannelList.css';
@import './chatMessageList.css';
@import './chatMessage.css';
@import './chatMessageReactions.css';
@import './chatThread.css';

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

.react-images__blanket {
  z-index: 3999 !important;
}

.react-images__positioner {
  z-index: 4000 !important;
}

.chat-input-styled-wrapper {
  padding: 4px 16px;
  padding-top: 12px;
  background: none;

  &.limited {
    padding: 4px 0px 0px 0px;
  }

  .str-chat__textarea {
    .rta__autocomplete {
      border-radius: var(--border-radius-md);
      width: 100%;
      bottom: calc(100% + 12px);
    }

    & > textarea {
      padding: 12px 40px 12px 15px;
      min-height: unset;
      word-break: break-word;

      background: theme(colors.gray.50);
      border-radius: 30px;
      color: rgba(31, 41, 55, 1);
      font-size: 0.9rem;
      box-sizing: border-box;
      align-items: center;
      font-family: museo-sans, sans-serif;
      font-weight: 400;
      line-height: 1.1876em;

      &:hover {
        background: theme(colors.gray.100);
      }

      &:focus {
        border-radius: 30px;
        background: theme(colors.gray.100);
        box-shadow: none;
      }

      & {
        ::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: theme(colors.gray.400);
          opacity: 1; /* Firefox */
        }

        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: theme(colors.gray.400);
        }

        ::-ms-input-placeholder {
          /* Microsoft Edge */
          color: theme(colors.gray.400);
        }
      }
    }
  }

  .str-chat__input-flat--textarea-wrapper
    .str-chat__emojiselect-wrapper
    .str-chat__tooltip {
    left: 12px;
    align-items: center;
  }

  .str-chat__input-flat-emojiselect {
    top: calc(100% - 40px);
    left: 8px;
  }

  .rfu-file-upload-button {
    position: absolute;
    top: calc(100% - 34px);
    right: 25px;
  }

  .str-chat__attachment {
    .str-chat__message-attachment-card--giphy {
      margin-top: 0px;
      border: 1px solid theme(colors.gray.300);
      margin: 4px;
      padding: 8px;
      border-radius: 12px;

      .str-chat__message-attachment-card--header {
        display: flex;
        justify-content: center;

        img {
          max-height: 225px;
          width: auto;
        }
      }

      .str-chat__message-attachment-card--content {
        display: none;
      }
    }
  }

  .str-chat__input--emojipicker {
    .emoji-mart-anchor-icon {
      display: flex;
      justify-content: center;
    }
  }
}

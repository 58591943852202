.chat-thread-styled-wrapper {
  display: flex;
  height: 100%;
  position: absolute;
  right: 0;
  box-shadow: theme(boxShadow.dark);
  z-index: 3000;
  border-top: 1px solid theme(colors.gray.100);

  .chatThread {
    /* margin-top: var(--lg-m); */
    margin-bottom: var(--sm-m);

    display: flex;
    flex-direction: column;
    height: 100%;
    -moz-box-shadow: theme(boxShadow.dark);
    -webkit-box-shadow: theme(boxShadow.dark);
    box-shadow: theme(boxShadow.dark);
    position: unset;
    background-color: white;
    width: 100%;

    &.str-chat__thread-limited-view {
      .str-chat__thread-header {
        height: 50px;
        min-height: 50px;
      }

      .str-chat__thread-list {
        padding: 0;

        .str-chat__list {
          .str-chat__ul {
            .str-chat__li {
              &.str-chat__li--top,
              &.str-chat__li--single {
                padding-top: calc(var(--sm-p) / 2);
                padding-bottom: calc(var(--sm-p) / 2);
                margin: 0px;
              }

              &.str-chat__li--bottom {
                margin: 0px;
              }

              .customMessageWrapper {
                .str-chat__reaction-list {
                  margin-left: 32px;
                }
              }
            }
          }

          .str-chat__typing-indicator {
            display: none;
          }
        }
      }
    }

    /* Main reply message */
    .str-chat__message,
    .str-chat__message-simple {
      margin-top: 0px;
      justify-content: flex-start;
    }

    .str-chat__thread-start {
      color: theme(colors.gray.800);
      background-color: theme(colors.gray.50);
      border: 1px solid theme(colors.gray.300);
      box-shadow: theme(boxShadow.smallest);
    }
  }

  .str-chat__thread-header {
    height: 80px;
    min-height: 80px;
    color: theme(colors.gray.500);

    .str-chat__square-button {
      background: none;
      border: none;
      border-radius: 8px;
      padding: 12px;

      &:hover {
        background-color: theme(colors.gray.100);
      }
    }
  }

  .str-chat__thread-list {
    padding: 0;

    .str-chat__ul {
      .str-chat__li {
        &.str-chat__li--top,
        &.str-chat__li--single {
          padding-top: calc(var(--sm-p) / 2);
          padding-bottom: calc(var(--sm-p) / 2);
          margin: 0px;
        }

        .customMessageWrapper {
          &:hover {
            background-color: theme(colors.gray.50);
            border-radius: 4px;

            .str-chat__message-simple__actions {
              border: 1px solid theme(colors.gray.300);
              background-color: theme(colors.gray.100);

              > * {
                display: flex;
              }
            }
          }

          .str-chat__message {
            .str-chat__message-inner {
              .str-chat__message-simple__actions {
                width: 48px !important;
              }
            }

            &.str-chat__message--me,
            &.str-chat__message-simple--me {
              justify-content: flex-start;

              .str-chat__message-inner {
                .str-chat__message-simple__actions {
                  flex-direction: row-reverse;
                }
              }
            }
          }
        }
      }
    }
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.hide-scrollbar {
  &::-webkit-scrollbar {
    display: none; /*Hide scrollbar for Chrome, Safari and Opera */
  }

  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.ch-select {
  background-image: none;
}

div[data-popper-placement^='top'] > .tooltip > .arrow {
  bottom: -15px;
  transform: rotate(180deg);
}

div[data-popper-placement^='bottom'] > .tooltip > .arrow {
  top: -15px;
}

.input-height {
  height: 47px;
}

.prose {
  line-height: 1.35 !important;
  font-size: 13px;
}

.prose br {
  display: none;
}

.prose pre {
  background-color: transparent !important;
  color: #25283c !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  border-left: 2px solid #8e8e9a !important;
  border-radius: 0 !important;
  line-height: 1.35 !important;
  white-space: pre-wrap !important;
}

.prose p {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}

:root {
  --onboard-font-family-normal: museo-sans, sans-serif;
  --onboard-font-family-semibold: museo-sans, sans-serif;
  --onboard-font-family-light: museo-sans, sans-serif;

  --onboard-modal-z-index: 3000;
}

.styledSelect [type='text']:focus {
  --tw-ring-color: none;
}

.pinned .pinned_visible {
  display: flex;
}

.special-collective-text {
  background: #0c5fe3;
  background: -webkit-linear-gradient(to right, #2062e5 0%, #d573e7 85%);
  background: -moz-linear-gradient(to right, #2062e5 0%, #d573e7 85%);
  background: linear-gradient(to right, #2062e5 0%, #d573e7 85%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-box-top {
  background: #ebf3ff;
  background: -webkit-linear-gradient(135deg, #ebf3ff 10%, #ffebff 100%);
  background: -moz-linear-gradient(135deg, #ebf3ff 10%, #ffebff 100%);
  background: linear-gradient(135deg, #ebf3ff 10%, #ffebff 100%);
}

.gradient-box {
  background: #1a9efd;
  background: -webkit-linear-gradient(140deg, #ea89fc 10%, #1a9efd 100%);
  background: -moz-linear-gradient(140deg, #ea89fc 10%, #1a9efd 100%);
  background: linear-gradient(140deg, #ea89fc 10%, #1a9efd 100%);
}

.home-gradient-box-1 {
  background: #0c5fe3;
  background: -webkit-linear-gradient(135deg, #ebf3ff 10%, #ffebff 100%);
  background: -moz-linear-gradient(135deg, #ebf3ff 10%, #ffebff 100%);
  background: linear-gradient(135deg, #ebf3ff 10%, #ffebff 100%);
}

.home-gradient-box-2 {
  background: #0c5fe3;
  background: -webkit-linear-gradient(140deg, #e6b6f2 10%, #75acff 100%);
  background: -moz-linear-gradient(140deg, #e6b6f2 10%, #75acff 100%);
  background: linear-gradient(140deg, #e6b6f2 10%, #75acff 100%);
}

.landing-page-gradient-box {
  background: #ebf3ff;
  background: -webkit-linear-gradient(135deg, #ebf3ff 10%, #fbf0ff 100%);
  background: -moz-linear-gradient(135deg, #ebf3ff 10%, #fbf0ff 100%);
  background: linear-gradient(135deg, #ebf3ff 10%, #fbf0ff 100%);
  padding-top: 60px;
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wallet-mobile-row {
  td {
    verticalalign: 'baseline';
  }
}
.banner-gradient-box {
  position: relative;
  background: #0c5fe3;
  background: -webkit-linear-gradient(140deg, #f0f6ff 00%, #fff7ff 100%);
  background: -moz-linear-gradient(140deg, #f0f6ff 00%, #fff7ff 100%);
  background: linear-gradient(140deg, #f0f6ff 00%, #fff7ff 100%);
  padding: 10px 0;
  text-align: center;
}

.banner-gradient-text {
  background: #0c5fe3;
  background: -webkit-linear-gradient(to right, #0e58cd 0%, #cf3dcb 85%);
  background: -moz-linear-gradient(to right, #0e58cd 0%, #cf3dcb 85%);
  background: linear-gradient(to right, #0e58cd 0%, #cf3dcb 85%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.wallet-list-row {
  border-bottom-width: 1px;
  border-bottom-color: theme(colors.gray.300);

  &:hover {
    .cell {
      background-color: theme(colors.gray.100);
    }

    .narrow-cell {
      background-color: theme(colors.gray.100);
    }

    .mobile-narrow-cell {
      background-color: theme(colors.gray.100);
    }

    th {
      background-color: theme(colors.gray.100);
    }
  }
}

.faq-list-container > :not([hidden]) ~ :not([hidden]) {
  border-top-width: 1px;
  border-top-color: theme(colors.gray.200);
}

:root {
  --list-width: 305px;
}

.chat-channel-list-styled-wrapper {
  height: 100%;
  width: 100%;
  flex: 1 0 0%;
  flex-direction: column;

  .str-chat-channel-list.messaging {
    background-color: #fff;
    width: var(--list-width);
    flex: 1;
    border-right: 1px solid theme(colors.gray.200);
    box-shadow: none;
    display: flex;
    flex-direction: column;
    height: auto;
    overflow: auto;
    /* min-height unset; */

    @media screen and (max-width: 800px) {
      position: initial;
      width: 100%;
      min-height: 100%;
    }

    @media screen and (max-width: 960px) {
      position: initial;
    }

    .str-chat__channel-search {
      background: none;
      padding: 16px 16px 0px 16px;

      input {
        border: 0px;
        background: none;
        border-radius: 0px;
        height: initial;

        &:focus {
          -moz-box-shadow: none;
          -webkit-box-shadow: none;
          box-shadow: none;
        }
      }

      .str-chat__channel-search-container {
        margin-top: 8px;
        padding: 0px;
        -moz-box-shadow: theme(boxShadow.dark);
        -webkit-box-shadow: theme(boxShadow.dark);
        box-shadow: theme(boxShadow.dark);
        border-radius: 8px;
        background: white;
        min-height: 200px;
        overflow: auto;
        z-index: 4000;

        .str-chat__channel-search-results-header {
          padding: 8px;
        }
      }

      .channel-search__result-user {
        display: flex;
        align-items: center;
        margin-left: 12px;
      }

      .channel-search__result-text {
        width: 100%;
        font-family: theme(fontFamily.sans);
        font-weight: 500;
        font-size: 14px;
        line-height: 120%;
        color: #2c2c30;
      }
    }

    .str-chat__channel-list-messenger {
      background-color: #fff;
      flex: 1;
      height: auto;
      overflow-y: auto;
      padding-bottom: 0px;

      .str-chat__channel-list-messenger__main.str-chat__channel-list-messenger-react__main {
        padding: var(--xs-p) var(--xs-p) 0 var(--xs-p);
        padding-bottom: 8px;
      }
    }
  }

  .str-chat__loading-channels {
    width: var(--list-width);
    height: 100%;
    padding: var(--md-p);
    background: #fff;
  }
}

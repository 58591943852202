@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

/* Quill Editors */
/* common css for edit and viewing */

@layer components {
  .ql-code-block {
    pre.ql-syntax {
      border-radius: 4px;
      border: 1px solid theme(colors.gray.200);
      background-color: theme(colors.gray.100);
      color: theme(colors.gray.700);
      overflow: visible;
    }

    pre {
      white-space: pre-wrap;
      margin-bottom: 5px;
      margin-top: 5px;
      padding: 5px 10px;
    }
  }
}

/* all quill content css */
.ql-container.ql-snow {
  border: none;
  font-size: 14px;
  font-family: museo-sans, sans-serif;

  .ql-editor {
    @apply ql-code-block;

    ul[data-checked='true'] > li::before {
      content: '\2611' !important;
      font-size: 19px;
      line-height: 20px;
      color: black;
    }

    ul[data-checked='false'] > li::before {
      content: '\2610' !important;
      font-size: 19px;
      line-height: 20px;
      color: black;
    }

    ul:not([data-checked]) > li::before {
      margin-left: -1.8em;
      margin-right: 0.6em;
    }

    a {
      color: theme(colors.blue.DEFAULT);
    }
  }
}

.ql-content {
  .ql-editor {
    padding: 0px;
  }
}

/* general edit mode in all quill */
.upstream-quill-editor.quill {
  .ql-toolbar.ql-snow {
    border: none;
    border-bottom: 1px solid #e1e1e1;
  }

  .ql-container.ql-snow {
    border: none;
  }

  .ql-editor {
    @apply ql-code-block;
    height: 180px;
  }
}

/* comment edit */
.forum-comment-quill.quill {
  display: flex;
  flex-direction: column-reverse;

  .ql-toolbar.ql-snow {
    border: none;
    border-top: 1px solid #e1e1e1;
  }

  .ql-editor {
    @apply ql-code-block;
    height: 130px;
  }
}
